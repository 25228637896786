import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions } from 'features/Game';
import { roundingFunc } from 'services/helpers/roundingFunc';

import Button from 'components/Button';
import BetItem from './BetItem';

import './BasketsClaster.scss';

const BasketsClaster = ({ globalIsPlaced, indexArr, remove }) => {
    const dispatch = useDispatch();
    const locale = useSelector(state => state.locale.locale);
    const bets = useSelector(state => state.game.bets);
    const tempCoef = useSelector(state => state.game.coef);
    const periodType = useSelector(state => state.game.periodType);
    const currency = useSelector(state => state.auth.currency);
    const platform = useSelector(state => state.auth.platform);
    const [isPlaced, setIsPlaced] = useState(false);

    const b = block(platform === 'mobile' ? 'baskets-claster' : 'basket-claster-desk');

    const clusterBets = useMemo(() => indexArr.map(idx => ({
        ...bets[idx],
        index: idx,
    })), [indexArr, bets]);

    useEffect(() => {
        if (clusterBets.find(bet => bet.betId)) {
            setIsPlaced(true);
        } else setIsPlaced(false);
    }, [clusterBets]);

    // Set button text
    const tempButtonText = useMemo(() => {
        if (isPlaced) {
            const sum = clusterBets.reduce((sum, cur) => sum + (cur.betId ? +cur.amount : 0), 0);

            return `${locale.cashout} ${periodType === 1 ? `${roundingFunc(tempCoef * sum)} ${currency}` : ''}`;

        } else return locale.cashout
    },
        [isPlaced, periodType, locale.cashout, tempCoef, clusterBets, currency]
    );

    // Cashout money function
    const cashoutBet = useCallback(() => {
        clusterBets.filter(bet => !!bet.betId).forEach(bet => {
            dispatch(actions.cashoutBet(bet.index, bet.betId));
        });
    }, [dispatch, clusterBets]);

    return platform === 'mobile' ? <div className={b()}>
        <BetItem remove={remove} index={indexArr[0]} globalIsPlaced={globalIsPlaced} />
        <BetItem remove={remove} index={indexArr[1]} globalIsPlaced={globalIsPlaced} />
        <div className={b('claster-cashout')}>
            <Button
                text={tempButtonText}
                size='container'
                isUppercase
                color={(periodType === 2 || (periodType === 1 && !isPlaced)) ? 'disabled' : 'orange'}
                fontSize='small'
                callBack={cashoutBet}
                isBlock={periodType === 2 || (periodType === 1 && !isPlaced)}
            />
        </div>
    </div> :
        <div className={b()}>
            <div className={b('bets-item-container')}>
                <BetItem remove={remove} index={indexArr[0]} globalIsPlaced={globalIsPlaced} />
                <BetItem remove={remove} index={indexArr[1]} globalIsPlaced={globalIsPlaced} />
            </div>
            <div className={b('claster-cashout')}>
                <Button
                    text={tempButtonText}
                    size='container'
                    isUppercase
                    color={(periodType === 2 || (periodType === 1 && !isPlaced)) ? 'disabled' : 'orange'}
                    fontSize='small'
                    callBack={cashoutBet}
                    isBlock={periodType === 2 || (periodType === 1 && !isPlaced)}
                />
            </div>
        </div>
};

export default BasketsClaster;
import { rules } from './rules';

export const locale = {
  // Advice
  editAdvice: 'You can set a nickname and avatar by clicking on the pencil',

  //General
  id: 'Id',
  ok: 'Ok',
  amount: 'Amount',

  //Main
  maxWin: 'Maximum win',
  playNow: 'Play now',
  topWins: 'Tops wins',
  roundID: 'Round ID',

  //Game
  min: 'Min',
  max: 'Max',
  placeBet: 'Place bet',
  bet: 'Bet',
  noName: 'No name',
  nextRound: 'Waiting for next round',

  //History
  bets: 'Bets',
  myBets: 'My bets',
  results: 'Results',
  betAmount: 'Bet amount',
  youWon: 'Victory! Your balance:',
  odd: 'Odds',
  win: 'Win',
  player: 'Player',
  historyRoundID: 'Round ID',
  historyBetAmount: 'Bet amount',
  historyOdd: 'Odds',
  historyWinAmount: 'Win Amount',
  promoList: 'Promo',
  noPromo: 'There is no promo right now',
  closePromo: 'get it',

  //Auth
  userUpdateSuccess: 'Data updated!',
  userUpdateFail: 'An error has occurred. Please try a different nickname or update the data later.',

  //Chat
  online: 'Online',
  enterMessage: 'Enter your message',
  openChat: 'Open chat',
  closeChat: 'Close chat',

  //Basket
  autoCashout: 'Auto cashout',
  autoBet: 'Auto bet',
  allIn: 'All-In',
  placedBet: 'Bet placed',
  cashout: 'Cashout',
  cancelBet: 'Cancel Bet',
  canceledBet: 'Your bet has been successfully canceled',
  betLimit: (amount, currency) => `Your bet for this round should not exceed ${amount} ${currency}`,
  totalBet: 'Total Bet',
  totalWin: 'Total Win',
  allBets: 'All Bets',

  // Settings
  sound: 'Sound',
  music: 'Music',
  animations: 'Animations',

  // Jackpot
  won: 'won',
  anotherWinnerNotify: (winner_name, jackpot_name, winner_amount, currency) => `Player ${winner_name} won a ${jackpot_name} of ${winner_amount} ${currency}`,
  congratulations: 'Congratulations',
  youWonJackpot: `You've won`,

  //Rules
  rules: 'Rules',
  howToPlay: 'How to play?',
  detailedRules: 'Detailed rules',
  rule1: 'BET before the start of the round',
  rule2: 'WATCH as Crash grow and your winnings increase',
  rule3: 'CASHOUT before Crash disappears and win X more!',
  rulesHtml: rules,

  //MegaWins 
  megaWinMega: 'Mega',
  megaWinWin: 'Win',

  //NewYear
  newYearTitle: 'HAPPY NEW YEAR!',
  player: 'player',
  text1: 'Dear',
  text2: 'We would like to congratulate you for spending this year with us.',
  text3: 'We hope you enjoyed every bet placed, and every possible win with our games. We are grateful to you for staying with FIABLE GAME.',
  text4: 'Therefore, we have collected for you interesting statistics for the past year.',
  statisticTitle: 'In 2023, together with FIABLE GAMES, you have:',
  statistic1: '• Won all of our games',
  statistic2: '• Claim your biggest win of',
  statistic3: '• Made as many as',
  statistic4: 'bets!',
  text5: 'We promise to delight you with new exciting games in the new 2024, huge wins and cool promotions!',
  text6: 'We wish you happiness, more gains and best of luck for next year!',
  text7: 'Thank you for staying with us, playing with us and winning with us!',
  text8: 'Sincerely, the administration and development team of your favourite casino provider.',
  fiableGames: 'FIABLE GAMES!',
}
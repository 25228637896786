import { addNotify } from 'features/Notify';
import { actions as authActions } from 'features/Auth';
import { actions as historyActions } from 'features/History';
import { actions as megaWinsActions } from 'features/MegaWins';


const actionTypes = {
  GET_SETTINGS_SUCCESS: 'game/GET_SETTINGS_SUCCESS',
  GET_ROUND_ID: 'game/GET_ROUND_ID',
  GET_WIN_CARD: 'game/GET_WIN_CARD',
  GET_COEFS_HISTORY: 'game/GET_COEFS_HISTORY',
  GET_TOP_WINS: 'game.GET_TOP_WIND',
  SET_ROUND_INFO: 'game/SET_ROUND_INFO',
  SET_IS_PROCESS: 'game/SET_IS_PROCESS',
  GET_INFO: 'game/GET_INFO',
  GET_CASHED_OUT: 'game/GET_CASHED_OUT',
  SET_BET_AMOUNT_IN_ROUND: 'game/SET_BET_AMOUNT_IN_ROUND',
  ADD_NEW_BASKET: 'game/ADD_NEW_BASKET',
  DELETE_BASKET: 'game/DELETE_BASKET',
  SET_AMOUNT: 'game/SET_AMOUNT',
  GET_BET_ID: 'game/GET_BET_ID',
}

const getInfo = data => dispatch => dispatch({ type: actionTypes.GET_INFO, payload: data });

const getSettings = data => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.GET_SETTINGS_SUCCESS, payload: data });
}

const setRoundInfo = data => dispatch => {
  dispatch({ type: actionTypes.SET_ROUND_INFO, payload: data })
}

const getRoundId = data => dispatch => {
  dispatch({ type: actionTypes.GET_ROUND_ID, payload: data })
  dispatch(historyActions.clearBets())
}

const addNewBasket = data => dispatch => {
  dispatch({ type: actionTypes.ADD_NEW_BASKET, payload: data });
}

const deleteBasket = data => dispatch => {
  dispatch({ type: actionTypes.DELETE_BASKET, payload: data });
}

const changeAmount = (index, value) => dispatch => {
  dispatch({ type: actionTypes.SET_AMOUNT, payload: {index, value} });
}

const getBetId = (index, id) => dispatch => {
  dispatch({ type: actionTypes.GET_BET_ID, payload: {index, id} });
}

const getTopWins = data => dispatch => dispatch({ type: actionTypes.GET_TOP_WINS, payload: data })

const placeBet = (amount, coef, isAutoCashout, isAutoBet, index) => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { currency, token } = getState().auth;
  const { locale } = getState().locale;
  const { roundID, gameName } = getState().game;
  const { max } = getState().game.limits;
  const { betAmountInRound } = getState().game;
  if (betAmountInRound + amount <= max) {
    const placeFunc = async() => {
      dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
      const response = await api.game.placeBet(token, { game_name: gameName, bet_amount: amount, game_id: roundID + 1, auto_cashout: isAutoCashout ? { point: coef } : undefined });
      if (response.success) {
        const { roundID: actualRoundId, periodType } = getState().game;

        const isSameRound = actualRoundId === roundID;
        const isNextRound = actualRoundId === roundID + 1;
        const isBetPlacingStatus = periodType === 2;

        if (!isSameRound && !(isNextRound && !isBetPlacingStatus)) {
          dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
          return;
        }
  
        dispatch({ type: actionTypes.SET_BET_AMOUNT_IN_ROUND, payload: amount })
        dispatch(authActions.getBalance(response.data.new_balance));
        dispatch(addNotify(`${locale.placedBet}! ${locale.roundID}: ${roundID}, ${locale.betAmount}: ${amount} ${currency}`, 'success'))
        dispatch(getBetId(index, response.data.bet_id));
      } else {
        dispatch(addNotify(locale.errorMessages[response.codeStatus?.toString()] || locale.errorMessages.defaultMessage, 'error'));
      }
      dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
    }
    setTimeout(() => placeFunc(), isAutoBet ? Math.trunc(Math.random() * 1000 + index * 1000) : 0)
  } else {
    dispatch(addNotify(locale.betLimit(max - betAmountInRound, currency), 'error'))
  }
}

const cashoutBet = (index, betId) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { token } = getState().auth;
  const { locale } = getState().locale;
  const { roundID, coef, gameName } = getState().game;
  const response = await api.game.cashoutBet(token, { game_name: gameName, game_id: roundID, current_point: +coef, bet_id: betId});
  if (response.success) {
    dispatch(getBetId(index, null));
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus?.toString()] || locale.errorMessages.defaultMessage, 'error'));
  }
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const getCashedOut = data => (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.GET_CASHED_OUT, payload: data })
  dispatch(historyActions.updatePlayersBets(data))
  const { userID, balance } = getState().auth;
  const { locale } = getState().locale;
  const { bets } = getState().game;
  if (userID === data.user_id) {
    const index = bets.findIndex(bet => bet.betId === data.bet_id);
    if (index !== -1) dispatch(getBetId(index, null));
    dispatch(authActions.getBalance(balance + data.win_amount));
    if (data.coefficient >= 20) {
      dispatch(megaWinsActions.showMegaWins(+data.win_amount));
    }
    else {
      dispatch(addNotify(`${locale.odd}: ${data.coefficient}. ${locale.win}! ${data.win_amount} ${data.currency}`, 'win'))
    }
  }
}

const getCoefsHistory = data => dispatch => dispatch({ type: actionTypes.GET_COEFS_HISTORY, payload: data })

const cancelBet = (bet_id, setPlaced) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { token } = getState().auth;
  const { locale } = getState().locale;
  const { roundID } = getState().game;
  const response = await api.game.cancelBet(token, { game_id: roundID + 1, bet_id });
  if (response.success) {
    setPlaced(false);
    const canceledBet = getState().history.bets.find(t => t.bet_id === bet_id);
    const { betAmountInRound } = getState().game;
    dispatch({ type: actionTypes.SET_BET_AMOUNT_IN_ROUND, payload: betAmountInRound - canceledBet.bet_amount })
    dispatch(addNotify(locale.canceledBet, 'success'));
    dispatch(historyActions.removePlayersBets(bet_id))
    dispatch(authActions.getBalance(+response.data.new_balance));
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus?.toString()] || locale.errorMessages.defaultMessage, 'error'));
  }
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}


export {
  actionTypes,
  getSettings,
  getRoundId,
  placeBet,
  setRoundInfo,
  getCoefsHistory,
  getTopWins,
  cashoutBet,
  getInfo,
  getCashedOut,
  cancelBet,
  addNewBasket,
  deleteBasket,
  changeAmount,
  getBetId,
}
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';
import block from 'bem-cn';

import { actions } from 'features/Game';

import Button from 'components/Button';
import SwitchBox from 'components/SwitchBox';
import Counter from 'components/Counter/Counter';
import minusSvg from 'services/img/minus.svg';

import './BetItem.scss';

const b = block('bet-item');

const BetItem = ({ remove, index, globalIsPlaced }) => {
    const dispatch = useDispatch();
    const platform = useSelector(state => state.auth.platform);
    const locale = useSelector(state => state.locale.locale);
    const limits = useSelector(state => state.game.limits);
    const bets = useSelector(state => state.game.bets);
    const currency = useSelector(state => state.auth.currency);
    const periodType = useSelector(state => state.game.periodType);
    const balance = useSelector(state => state.auth.balance);
    const [isAutoBet, setAutoBet] = useState(false);
    const [isPlaced, setPlaced] = useState(false);

    useEffect(() => {
        if (bets[index].betId) {
            setPlaced(true);
        } else setPlaced(false)
    }, [bets, index])

    // Place bet function
    const placeBet = useCallback(() => dispatch(actions.placeBet(+bets[index].amount, 0, 0, isAutoBet, index)),
        [bets, dispatch, isAutoBet, index]);

    // For validate amount
    const validateChangeAmount = useCallback(value => {
        if (!isPlaced) {
            if (value > limits.max) dispatch(actions.changeAmount(index, +limits.max.toFixed(1)))
            else if (value < limits.min) dispatch(actions.changeAmount(index, +limits.min.toFixed(1)))
            else if (+(+value).toFixed(1) > balance) {
                if (balance < limits.min) {
                    dispatch(actions.changeAmount(index, +limits.min.toFixed(1)));
                } else {
                    dispatch(actions.changeAmount(index, Math.floor(balance * 10, 1) / 10));
                }
            }
            else dispatch(actions.changeAmount(index, +(+value).toFixed(1)));
        }
    }, [balance, isPlaced, limits.max, limits.min, dispatch, index])

    // For minus amount
    const minusAmount = useCallback(value => {
        if (!isPlaced) {
            if (value >= limits.min) dispatch(actions.changeAmount(index, +(value.toFixed(1))));
            else dispatch(actions.changeAmount(index, +(limits.min.toFixed(1))));
        }
    }, [isPlaced, limits.min, dispatch, index])

    // For validate input Amount
    const validateTempAmount = useCallback(value => {
        if (!isPlaced) {
            if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
                value.toString().split('').filter(t => t === '.').length < 2) {
                if (value.toString().split('')[0] === '.') {
                    dispatch(actions.changeAmount(index, +value.substring(1, value.length)))
                } else dispatch(actions.changeAmount(index, +value))
            }
        }
    }, [isPlaced])

    // Auto bet
    useEffect(() => {
        if (periodType === 2 && isAutoBet) {
            placeBet();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodType, isAutoBet])


    return <div className={b()}>
        <div className={b('label')}>{`${locale.bet} ${index + 1}`}</div>
        <div className={b('remove-button', { isDisabled: globalIsPlaced || isPlaced })} onClick={isPlaced || globalIsPlaced ? f => f : () => remove(index)}>
            <SVGInline svg={minusSvg} className={b('remove-img').toString()} />
        </div>
        <div className={b('auto-bet')}>
            <span className={b('text')}>{locale.autoBet}</span>
            <SwitchBox value={isAutoBet} changeValue={value => setAutoBet(value)} />
        </div>
        <div className={b('container')}>
            <div className={b('coefficient')} onClick={() => validateChangeAmount(limits.min)}>{locale.min}</div>
            <div className={b('coefficient')} onClick={() => validateChangeAmount(bets[index].amount * 2)}>X2</div>
            <div className={b('coefficient')} onClick={() => validateChangeAmount(limits.max)}>{locale.max}</div>
        </div>
        <div className={b('container')}>
            <Counter
                amount={bets[index].amount}
                step={limits.min}
                unit={platform === 'mobile' ? currency : null}
                isBlock={isPlaced}
                minusBlock={bets[index].amount === limits.min}
                plusBlock={bets[index].amount === limits.max || bets[index].amount > balance}
                validatorPlus={validateChangeAmount}
                validatorMinus={minusAmount}
                validationInput={validateTempAmount}
                validatorOnBlur={validateChangeAmount}
            />
        </div>
        <div className={b('container')}>
            <Button
                text={locale.placeBet}
                size='container'
                isUppercase
                isBlock={isPlaced}
                fontSize="small"
                isDisabled={periodType === 1 || isPlaced}
                color='orange'
                callBack={placeBet}
            />
        </div>
    </div>
};

export default BetItem;
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import { actions } from 'features/Game';
import { roundingFunc } from 'services/helpers/roundingFunc';

import Button from 'components/Button';
import SwitchBox from 'components/SwitchBox';
import Counter from 'components/Counter';
import minusSvg from 'services/img/minus.svg';

import './Basket.scss';

const Basket = ({ removeBasket, index, basketsCounts, globalIsPlaced }) => {
  const dispatch = useDispatch();
  const limits = useSelector(state => state.game.limits);
  const currency = useSelector(state => state.auth.currency);
  const tempCoef = useSelector(state => state.game.coef);
  const bets = useSelector(state => state.game.bets);
  const locale = useSelector(state => state.locale.locale);
  const lang = useSelector(state => state.locale.lang);
  const platform = useSelector(state => state.auth.platform);
  const balance = useSelector(state => state.auth.balance);
  const periodType = useSelector(state => state.game.periodType);
  const isProcess = useSelector(state => state.game.isProcess);
  const [isPlaced, setPlaced] = useState(false);
  const [isAutoBet, setAutoBet] = useState(false);
  const [isAutoCashout, setAutoCashout] = useState(false);
  const [coef, setCoef] = useState(5);
  const b = block(platform === 'mobile' ? 'basket' : 'basket-desktop');

  useEffect(() => {
    if (bets[index].betId) {
      setPlaced(true);
    } else setPlaced(false)
  }, [bets, index])

  // Place bet function
  const placeBet = useCallback(() => dispatch(actions.placeBet(+bets[index].amount, +coef, isAutoCashout, isAutoBet, index)),
    [bets, coef, dispatch, isAutoBet, isAutoCashout, index]);

  // Cashout money function
  const cashoutBet = useCallback(() => {
    dispatch(actions.cashoutBet(index, bets[index].betId))
  }, [dispatch, index, bets]);

  // Auto bet
  useEffect(() => {
    if (periodType === 2 && isAutoBet) {
      placeBet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodType, isAutoBet])

  // For validate amount
  const validateChangeAmount = useCallback(value => {
    if (!isPlaced) {
      if (value > limits.max) dispatch(actions.changeAmount(index, +limits.max.toFixed(1)))
      else if (value < limits.min) dispatch(actions.changeAmount(index, +limits.min.toFixed(1)))
      else if (coef * value > limits.win) dispatch(actions.changeAmount(index, +(limits.win / coef).toFixed(1)))
      else if (+(+value).toFixed(1) > balance) {
        if (balance < limits.min) {
          dispatch(actions.changeAmount(index, +limits.min.toFixed(1)))
        } else {
          dispatch(actions.changeAmount(index, Math.floor(balance * 10, 1) / 10))
        }
      }
      else dispatch(actions.changeAmount(index, +(+value).toFixed(1)));
    }
  }, [balance, coef, isPlaced, limits.max, limits.min, limits.win, dispatch, index])


  // For validate input Amount
  const validateTempAmount = useCallback(value => {
    if (!isPlaced) {
      if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
        value.toString().split('').filter(t => t === '.').length < 2) {
        if (value.toString().split('')[0] === '.') {
          dispatch(actions.changeAmount(index, value.substring(1, value.length)))
        } else dispatch(actions.changeAmount(index, value))
      }
    }
  }, [isPlaced])


  // For minus amount
  const minusAmount = useCallback(value => {
    if (!isPlaced) {
      if (value >= limits.min) dispatch(actions.changeAmount(index, +(value.toFixed(1))))
      else dispatch(actions.changeAmount(index, +(limits.min.toFixed(1))));
    }
  }, [isPlaced, limits.min, dispatch, index])

  // For validate input coef
  const validateChangeCoef = value => {
    if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
      value.toString().split('').filter(t => t === '.').length < 2 && isAutoCashout) {
      if (value < 1.01 && value !== '' && +value !== 1) setCoef(1.01)
      else if (bets[index].amount * value > limits.win) setCoef(limits.win / bets[index].amount)
      else setCoef(value);
    }
  }

  // For validate after blur coef
  const validateBlurCoef = value => {
    if (isAutoCashout) {
      if (+value > 1.1) {
        setCoef(+(+value).toFixed(2))
      } else if (+value > limits.win / bets[index].amount) {
        setCoef(+(limits.win / bets[index].amount).toFixed(2))
      } else {
        setCoef(1.01)
      }
    }
  }

  // For keyboard validate coef
  const keyboardCoefValidate = value => {
    if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
      value.toString().split('').filter(t => t === '.').length < 2) {
      if (value < 1.01) validateBlurCoef(1.01)
      else if (bets[index].amount * value > limits.win) validateBlurCoef(limits.win / bets[index].amount)
      else validateBlurCoef(value);
    }
  }

  // Set button click func
  const tempFunc = useMemo(() => {
    if (isPlaced) {
      if (periodType === 1) {
        return cashoutBet
      } else {
        // return cancelBet
      }
    }
    if (!isPlaced) {
      return placeBet
    }
  },
    [isPlaced, periodType, cashoutBet, placeBet]
  )

  // Set button text
  const tempButtonText = useMemo(() => {
    if (isPlaced) {
      if (periodType === 1) {
        return `${locale.cashout} ${periodType === 1 ? `${roundingFunc(tempCoef * bets[index].amount)} ${currency}` : ''}`
      } else {
        return `${locale.cashout} ${periodType === 1 ? `${roundingFunc(tempCoef * bets[index].amount)} ${currency}` : ''}`
      }
    }
    if (!isPlaced) {
      return locale.placeBet
    }
  },
    [isPlaced, periodType, locale.cashout, locale.placeBet, tempCoef, bets, currency, index]
  )

  return platform === 'mobile' ?
    // MOBILE
    <article className={b({ isBlock: isPlaced })}>
      <div className={b('bets-container')}>
        <div className={b('left')}>
          <div className={b('row', { alwaysActive: true })}>
            <span className={b('text')}>{locale.autoBet}</span>
            <SwitchBox value={isAutoBet} changeValue={value => setAutoBet(value)} />
          </div>
          <div className={b('row')}>
            <Counter
              amount={bets[index]?.amount}
              step={limits.min}
              unit={currency}
              isBlock={isPlaced}
              minusBlock={bets[index]?.amount === limits.min}
              plusBlock={bets[index]?.amount === limits.max || bets[index].amount > balance}
              validatorPlus={validateChangeAmount}
              validatorMinus={minusAmount}
              validationInput={validateTempAmount}
              validatorOnBlur={validateChangeAmount}
            />

          </div>
          <div className={b('row')}>
            <div className={b('bet-block')} onClick={() => validateChangeAmount(limits.min)}>
              {locale.min}
            </div>
            <div className={b('bet-block')} onClick={() => validateChangeAmount(bets[index].amount * 2)}>
              x2
            </div>
            <div className={b('bet-block')} onClick={() => validateChangeAmount(balance)}>
              {locale.max}
            </div>
          </div>
        </div>
        <div className={b('right')}>

          <div className={b('row')}>
            <span className={b('text')}>{locale.autoCashout}</span>
            <SwitchBox value={isAutoCashout} changeValue={value => isPlaced ? isPlaced : setAutoCashout(value)} />

          </div>
          <div className={b('row')}>
            <Counter
              amount={+coef}
              step={0.25}
              unit='X'
              isBlock={!isAutoCashout}
              minusBlock={coef === 1.01}
              plusBlock={coef === (limits.win / bets[index].amount).toFixed(2)}
              validatorPlus={keyboardCoefValidate}
              validatorMinus={keyboardCoefValidate}
              validationInput={validateChangeCoef}
              validatorOnBlur={validateBlurCoef}
            />
          </div>
          <div className={b('row')}>
            <Button
              text={tempButtonText}
              size='container'
              isUppercase
              isBlock={isPlaced}
              fontSize="small"
              isDisabled={isProcess || (periodType === 1 && !isPlaced) || (periodType === 2 && isPlaced)}
              color={isPlaced && periodType === 2 ? 'red' : 'orange'}
              callBack={tempFunc} />
          </div>
        </div>
        {basketsCounts !== 1 ? <div className={b('more-basket')} onClick={globalIsPlaced || isPlaced ? f => f : removeBasket}>
          <SVGInline svg={minusSvg} className={b('more-plus').toString()} />
        </div> : null}
      </div>
    </article> :
    <article className={b({ isBlock: isPlaced, lang, isDouble: basketsCounts > 1 })}>
      {basketsCounts === 1 ? <React.Fragment>
        <div className={b('bets-container')}>
          <div className={b('left')}>
            <div className={b('row')}>
              <Counter
                amount={bets[index]?.amount}
                step={limits.min}
                unit={currency}
                isBlock={isPlaced}
                minusBlock={bets[index]?.amount === limits.min}
                plusBlock={bets[index]?.amount === limits.max || bets[index].amount > balance}
                validatorPlus={validateChangeAmount}
                validatorMinus={minusAmount}
                validationInput={validateTempAmount}
                validatorOnBlur={validateChangeAmount}
              />
            </div>
            <div className={b('row')}>
              <div className={b('bet-block', { size: 'big' })} onClick={() => validateChangeAmount(limits.min)}>
                {locale.min}
              </div>
              <div className={b('bet-block', { size: 'big' })} onClick={() => validateChangeAmount(bets[index]?.amount * 2)}>
                x2
              </div>
            </div>
            <div className={b('row')}>
              <div className={b('bet-block', { size: 'big' })} onClick={() => validateChangeAmount(bets[index]?.amount * 3)}>
                x3
              </div>
              <div className={b('bet-block', { size: 'big' })} onClick={() => validateChangeAmount(balance)}>
                {locale.allIn}
              </div>
            </div>
            <div className={b('row', { alwaysActive: true })}>
              <span className={b('text')}>{locale.autoBet}</span>
              <SwitchBox value={isAutoBet} changeValue={value => setAutoBet(value)} />
            </div>
          </div>
          <div className={b('right')}>
            <div className={b('row')}>
              <Counter
                amount={+coef}
                step={0.25}
                unit='X'
                isBlock={!isAutoCashout}
                minusBlock={coef === 1.01}
                plusBlock={coef === (limits.win / bets[index].amount).toFixed(2)}
                validatorPlus={keyboardCoefValidate}
                validatorMinus={keyboardCoefValidate}
                validationInput={validateChangeCoef}
                validatorOnBlur={validateBlurCoef}
              />
            </div>
            <div className={b('row')}>
              <span className={b('text')}>{locale.autoCashout}</span>
              <SwitchBox value={isAutoCashout} changeValue={value => isPlaced ? isPlaced : setAutoCashout(value)} />
            </div>
            <div className={b('row', { size: 'big' })}>
              <Button
                text={tempButtonText}
                size='container'
                isUppercase
                isDisabled={isProcess || (periodType === 1 && !isPlaced) || (periodType === 2 && isPlaced)}
                color={isPlaced && periodType === 2 ? 'red' : 'orange'}
                isBlock={isPlaced}
                callBack={tempFunc} />
            </div>
          </div>
        </div>
      </React.Fragment> :
        <React.Fragment>
          <div className={b('row', { isDouble: basketsCounts > 2 })}>
            <Counter
              amount={bets[index]?.amount}
              step={limits.min}
              unit={currency}
              isBlock={isPlaced}
              minusBlock={bets[index]?.amount === limits.min}
              plusBlock={bets[index]?.amount === limits.max || bets[index].amount > balance}
              validatorPlus={validateChangeAmount}
              validatorMinus={minusAmount}
              validationInput={validateTempAmount}
              validatorOnBlur={validateChangeAmount}
            />
            <div className={b('switch-container')}>
              <span className={b('text')}>{locale.autoBet}</span>
              <SwitchBox value={isAutoBet} changeValue={value => setAutoBet(value)} />
            </div>
            <div className={b('more-basket')} onClick={globalIsPlaced || isPlaced ? f => f : removeBasket}>
              <SVGInline svg={minusSvg} className={b('more-plus').toString()} />
            </div>
          </div>
          <div className={b('row', { isDouble: basketsCounts > 2 })}>
            <div className={b('bet-block')} onClick={() => validateChangeAmount(limits.min)}>
              {locale.min}
            </div>
            <div className={b('bet-block')} onClick={() => validateChangeAmount(bets[index]?.amount * 2)}>
              x2
            </div>
            <div className={b('bet-block')} onClick={() => validateChangeAmount(bets[index]?.amount * 3)}>
              x3
            </div>
            <div className={b('bet-block')} onClick={() => validateChangeAmount(balance)}>
              {locale.allIn}
            </div>
          </div>
          <div className={b('row', { isDouble: basketsCounts > 2 })}>
            <Counter
              amount={+coef}
              step={0.25}
              unit='X'
              isBlock={!isAutoCashout}
              minusBlock={coef === 1.01}
              plusBlock={coef === (limits.win / bets[index].amount).toFixed(2)}
              validatorPlus={keyboardCoefValidate}
              validatorMinus={keyboardCoefValidate}
              validationInput={validateChangeCoef}
              validatorOnBlur={validateBlurCoef}
            />
            <div className={b('switch-container')}>
              <span className={b('text')}>{locale.autoCashout}</span>
              <SwitchBox value={isAutoCashout} changeValue={value => isPlaced ? isPlaced : setAutoCashout(value)} />
            </div>
          </div>
          <div className={b('row', { isDouble: basketsCounts > 2 })}>
            <Button
              text={tempButtonText}
              size='container'
              isUppercase
              isDisabled={isProcess || (periodType === 1 && !isPlaced) || (periodType === 2 && isPlaced)}
              color={isPlaced && periodType === 2 ? 'red' : 'orange'}
              isBlock={isPlaced}
              callBack={tempFunc} />
          </div>
        </React.Fragment>
      }
    </article>
}

export default Basket;
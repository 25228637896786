import React, { useCallback } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import Input from 'components/Input';
import minusSvg from 'services/img/minus.svg';
import plusSvg from 'services/img/plus.svg';

import './Counter.scss';

const b = block('counter')

const Counter = ({
    amount,
    unit,
    step,
    isBlock,
    plusBlock,
    minusBlock,
    validatorPlus,
    validatorMinus,
    validationInput,
    validatorOnBlur,
}) => {

    const minusAmount = useCallback(value => {
        validatorMinus(value)
    }, [validatorMinus]);

    const plusAmount = useCallback(value => {
        validatorPlus(value);
    }, [validatorPlus]);

    const inputAmount = useCallback(value => {
        validationInput(value);
    }, [validationInput])

    const blurAmount = useCallback(value => {
        validatorOnBlur(value)
    }, [validatorOnBlur]);

    return <div className={b({ isBlock })}>
        <div className={b('change-item', { isBlock: isBlock || minusBlock })}
            onClick={isBlock || minusBlock ? f => f : () => minusAmount(+amount - step)}
        >
            <SVGInline svg={minusSvg} className={b('change-icon').toString()} />
        </div>
        <div className={b('change-item', {isBlock: isBlock})}>
            <Input
                value={amount}
                label={unit}
                onChange={e => inputAmount(e.currentTarget.value)}
                onBlur={() => blurAmount(amount)}
                unBorder
                centerText
            />
        </div>
        <div className={b('change-item', { isBlock: isBlock || plusBlock })}
            onClick={isBlock || plusBlock ? f => f : () => plusAmount(+amount + step)}
        >
            <SVGInline svg={plusSvg} className={b('change-icon').toString()} />
        </div>
    </div>
};

export default Counter;
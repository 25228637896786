import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import { actions } from 'features/Game';

import plusSvg from 'services/img/plus.svg';
import Basket from '../Basket';
import BasketsClaster from '../BasketsClaster';

import './BasketModel.scss';

const b = block('basket-model');

const BasketModel = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const limits = useSelector(state => state.game.limits);
  const bets = useSelector(state => state.game.bets);
  const currency = useSelector(state => state.auth.currency);
  const tempCoef = useSelector(state => state.game.coef);
  const periodType = useSelector(state => state.game.periodType);
  const platform = useSelector(state => state.auth.platform);
  const [isPlaced, setIsPlaced] = useState(false);

  useEffect(() => {
    if (bets.length === 0)
      dispatch(actions.addNewBasket({ amount: limits.min, betId: null }));
  }, [bets, dispatch, limits]);

  useEffect(() => {
    if (bets?.find(bet => bet.betId)) {
      setIsPlaced(true);
    } else setIsPlaced(false);
  }, [bets]);

  const addBaskets = useCallback(() => {
    if (bets.length < 4) {
      dispatch(actions.addNewBasket({ amount: limits.min, betId: null }));
    }
  }, [bets, limits, dispatch]);

  const removeBasket = useCallback((index) => {
    if (bets.length > 1) {
      dispatch(actions.deleteBasket(index));
    }
  }, [bets, dispatch]);

  const basketsView = useMemo(() => {
    if (bets.length <= 3) {
      return bets.map((t, i) => t ? <Basket
        key={i}
        index={i}
        removeBasket={() => removeBasket(i)}
        basketsCounts={bets.length}
        globalIsPlaced={isPlaced}
      /> : null)
    } else return <div className={b('clasters-container')}>
      <BasketsClaster indexArr={[0, 1]} remove={removeBasket} globalIsPlaced={isPlaced} />
      <BasketsClaster indexArr={bets.length > 3 ? [2, 3] : [2]} remove={removeBasket} globalIsPlaced={isPlaced} />
    </div>

  }, [bets, isPlaced, removeBasket]);

  // Cashout money function
  const cashoutBet = useCallback(() => {
    bets.filter(bet => !!bet.betId).forEach(bet => {
      dispatch(actions.cashoutBet(bet.index, bet.betId));
    });
  }, [dispatch, bets]);

  return <article className={b({isClaster: bets.length > 3 && platform === 'desktop'})}>
    {basketsView}
    {bets.length > 3 ? <div className={b('global-cashout')}>
      <div className={b('container', { isDisabled: periodType === 2 || (periodType === 1 && !isPlaced) })}>
        <div className={b('label')}>{locale.totalBet}</div>
        {`${bets.reduce((sum, bet) => bet.betId ? sum + bet.amount : sum, 0).toFixed(2)}${currency}`}
      </div>
      <div className={b('container', { isCashout: true, isDisabled: periodType === 2 || (periodType === 1 && !isPlaced) })}
        onClick={periodType === 2 || (periodType === 1 && !isPlaced) ? f => f : cashoutBet}
      >
        {locale.cashout}
        <div>{locale.allBets}</div>
      </div>
      <div className={b('container', { isDisabled: periodType === 2 || (periodType === 1 && !isPlaced) })}>
        <div className={b('label')}>{locale.totalWin}</div>
        {`${bets.reduce((sum, bet) => bet.betId ? sum + bet.amount * tempCoef : sum, 0).toFixed(2)}${currency}`}
      </div>
    </div> : null}
    {bets.length > 3 ? null : <div className={b('add-basket', { isDisabled: isPlaced, isDouble: bets.length > 1 })} onClick={isPlaced ? f => f : addBaskets}>
      <SVGInline svg={plusSvg} className={b('more-plus').toString()} />
    </div>}
  </article>
}

export default BasketModel;